class IsLightbox extends HTMLDivElement{
	constructor() {
		super();
		let container = document.querySelector('#lightbox-fallback');

		if(!container) {
			container = document.createElement('div');
			container.id = 'lightbox-fallback';
			document.body.appendChild(container);
		}

		let href = $(this).data('href');
		let album = $(this).data('album');

		let link = document.querySelector(`[href="${href}"]`);

		if(!link) {
			link = document.createElement('a');
			link.href = href;
			link.dataset.lightbox = album;
			container.appendChild(link);
		}

		this.addEventListener('click', function(e) {
			e.preventDefault();

			link.click();
		})
	}
}

customElements.define('is-lightbox', IsLightbox, {extends: 'div'});
