(function() {
	let btn      = document.querySelector('.mobile__controls .btn');
	let topo     = document.querySelector('.topo');
	let targets  = [document.body, document.querySelector('.topo__main')];
	let active   = 'topo__main--shown';

	if(btn) {
		btn.addEventListener('click', function(e) {
			e.preventDefault();

			targets.forEach(target=> target.classList.add(active));
			btn.setAttribute('aria-expanded', true);

			let bck = document.createElement('div');

			bck.className = 'backdrop';
			topo.appendChild(bck);
			bck.addEventListener('click', function(e) {
				e.preventDefault();

				targets.forEach(target=> target.classList.remove(active));
				btn.setAttribute('aria-expanded', false);
				bck.classList.add('hide');
				bck.addEventListener('animationend', function() {
					topo.removeChild(bck);
				});
			});
		});
	}
})();
