(function() {
	let ranges = document.querySelectorAll('.range__macies');

	ranges.forEach(range => {
		let input = range.querySelector('input');
		let track = range.querySelector('.range__macies__input--track');
		let btns  = range.querySelectorAll('button');
		let max = input.max;
		let current = (input.value / max) * 100;

		track.style.setProperty('--v', current+'%');

		setButton(input.value);

		btns.forEach(btn=> {
			btn.addEventListener('click', function(){
				input.value = parseFloat(btn.dataset.value);
				current = (input.value / max) * 100;
				track.style.setProperty('--v', current+'%');
				setButton(input.value);
			})
		});

		input.addEventListener('input', function() {
			current = (input.value / max) * 100;

			track.style.setProperty('--v', current+'%');
			setButton(input.value);
		});

		function setButton(value) {
			btns.forEach(btn=> {
				if(btn.dataset.value == value) {
					btn.classList.add('active');
				}else{
					btn.classList.remove('active');
				}
			});
		}
	});

})();
