(function() {
	let tables = document.querySelectorAll('table');

	tables.forEach(table=> {
		let parent = table.closest('.table-responsive');

		if(!parent) {
			table.insertAdjacentHTML('afterend', '<div class="table-responsive"></div>');
			parent = table.nextElementSibling;
			parent.appendChild(table);
		}
	});
})();
