class LightboxItem extends HTMLDivElement{
	constructor() {
		super();
		let href = this.dataset.href;
		let alt = this.dataset.title || '';
		let albumName = this.dataset.islightbox;

		let container = document.querySelector('#lightbox-item');

		if(!container) {
			container    = document.createElement('div');
			container.id = 'lightbox-item';
			let css = document.createElement('link');

			css.rel = 'stylesheet';
			css.href = 'https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.1/css/lightbox.min.css';
			css.type = 'text/css';

			document.head.appendChild(css);

			let js = document.createElement('script');

			js.src = 'https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.1/js/lightbox.min.js';

			document.body.appendChild(js);

			document.body.appendChild(container);
		}

		let item = document.querySelector(`[href="${href}"]`);

		if(!item) {
			item = document.createElement('a');
			item.href = href;
			item.dataset.lightbox=albumName;

			if(alt) item.title = alt;

			container.appendChild(item);

			this.addEventListener('click', function(e) {
				e.preventDefault();

				item.click();
			});
		}
	}
}

customElements.define('lightbox-item', LightboxItem, {extends: 'div'});
