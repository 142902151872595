(function() {
	const parent = document.querySelector('.lojas__probel');

	if(parent) {
		const btn    = parent.querySelector('.btn');
		let expanded = false;

		btn.setAttribute('aria-expanded', expanded);

		btn.addEventListener('click', function(e) {
			e.preventDefault();

			expanded = !expanded;
			parent.classList.toggle('open');
			btn.setAttribute('aria-expanded', expanded);
			btn.blur();
		});
	}
})();
